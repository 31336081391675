<template>
  <section class="container pb-8">
    <div class="error-bg mb-3 mb-md-8" v-if="PageInfo.data.length === 0">
      <div>查無題庫資料</div>
    </div>

    <ul  class="mb-3" v-else-if="PageInfo.data.length !== 0">
      <li
        v-for="(item, index) in PageInfo.data"
        :key="index"
        class="exam-history"
      >
        <h5>{{ item.examinationStartDate | toTaipei | moment('YYYY/MM/DD HH:mm:ss') }}</h5>

        <p class="exam-history__detail">
          <span>錯誤率：{{ item.questionIncorrect }}/{{ item.questionActualCount }}</span>
          <span>測驗時間：{{ item.questionTime }}分鐘</span>
        </p>

        <router-link
          class="btn btn-primary exam-history__btn"
          :to="`/practices/examReview/${subjectId}/${item.examinationId}`"
        >
          查看
        </router-link>
      </li>
    </ul>

    <!-- 分頁 -->
    <Pagination
      @GetList="GetList()"
      :page.sync="Pagination.pageIndex"
      :page-row="Pagination.pageSize"
      :total-count="PageInfo.count"
    />
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GetPracticeSbujectBreadcrumb } from '@/api/BuyPracticeApi';
import { GetExaminations } from '@/api/MemberExamination';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      subjectId: 0,
      Args: { subjectId: '' },
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          subjectId: '',
        },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    const { id } = this.$route.params;
    this.subjectId = id;
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Args = { subjectId: id };
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          subjectId: id,
        },
      };
      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    // 然後再針對頁面進行初始化資料存取
    this.GetList();
    this.GetBreadcrumb();
  },
  methods: {
    GetBreadcrumb() {
      const postData = {
        Id: this.$route.params?.id,
      };

      GetPracticeSbujectBreadcrumb(postData)
        .then((response) => {
          if (response.data.statusCode === 0) {
            const { data } = response.data;

            if (!this._.isEmpty(data)) {
              this.$emit('update:currentPage', [
                {
                  name: 'practicesCategory',
                  title: `${data.subJectName}`,
                  path: `/practices/${data.subjectId}`,
                },
                {
                  name: 'exam',
                  title: '考試去',
                  path: `/practices/exam/${data.subjectId}`,
                },
                {
                  name: 'examHistory',
                  title: '試卷紀錄',
                  path: '',
                },
              ]);
            }
          }
        });
    },
    GetList() {
      this.Args = { subjectId: this.$route.params?.id };
      this.Pagination.data = { ...this.Args };
      GetExaminations(this.Pagination)
        .then((response) => {
          if (response.data.statusCode === 0) {
            this.PageInfo.count = response.data.count;
            this.PageInfo.data = response.data.data;

            if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
              this.Pagination.pageIndex = 1;
              this.GetList();
            }
          }
        })
        .catch(() => {
          this.$router.replace('/error').catch();
        });
    },
    Percentage(item) {
      const percentage = Math.round((item.questionActualCount / item.questionTotal) * 100);

      // eslint-disable-next-line no-restricted-globals
      return isNaN(percentage) ? 0 : percentage;
    },
  },
};
</script>

<style></style>
